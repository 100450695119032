<template>
  <div :class="modalClasses" role="dialog">

    <div :class="contentClasses">

      <div class="flex justify-center">
        <component :is="iconComponent" :style="iconStyle" />
      </div>

      <h3 class="headline-medium mb-s12">
        {{ title }}
      </h3>

      <ButtonV2
        class="capitalize mt-s32"
        size="large"
        testId="btn-close"
        :label="$t('navigation.close')"
        @onClick="hideModal"
        wide
      />
    </div>

  </div>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';
import { IconFailedSignDark, IconFailedSignLight, IconSuccessSignLight, IconSuccessSignDark } from '@/assets/icons';

export default {
  name: 'WithdrawalConfirmation',
  components: {
    ButtonV2,
    IconFailedSignDark,
    IconFailedSignLight,
    IconSuccessSignLight,
    IconSuccessSignDark,
  },
  props: {
    success: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    contentClasses(){
      return {
        'content': true,
        'content--large': !this.isMobileDevice,
        'content--small': this.isMobileDevice,
      };
    },
    iconComponent(){
      return `Icon${this.type}Sign${this.theme}`;
    },
    iconStyle(){
      return this.success ? {
        width: '312px',
        height: '320px',
      } : {
        width: '235px',
        height: '320px',
      };
    },
    modalClasses(){
      return {
        'modal-background': this.success,
        'modal-background-failed': !this.success,
      };
    },
    title() {
      return this.success ?
        this.$t('wallet.modal.withdrawal_confirmed.title_success') :
        this.$t('wallet.modal.withdrawal_confirmed.title_failed');
    },
    theme() {
      return this.currentTheme === 'light' ? 'Light' : 'Dark';
    },
    type() {
      return this.success ? 'Success' : 'Failed';
    }
  },
};
</script>

<style scoped>
.modal-background {
  background: url(~@/assets/img/bg-wallet-success-modal-light.webp);
  background-size: cover;
  background-position: 0 -60px;
  background-repeat: no-repeat;
}

.dark .modal-background {
  background: url(~@/assets/img/bg-wallet-success-modal-dark.webp);
  background-repeat: no-repeat;
}

.modal-background-failed {
  background: url(~@/assets/img/bg-wallet-failed-modal.webp);
  background-size: cover;
  background-position: 0 -110px;
  background-repeat: no-repeat;
}

.content {
  @apply flex flex-col justify-between;
}

.content--large {
  @apply py-s40 px-s20;
  max-width: 450px;
}

.content--small {
  @apply p-s20;
  min-height: 100vh;
  width: 100%;
}
</style>
